import { useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Tree,
  Input,
  Skeleton,
  Tooltip,
  notification,
  message,
  Popover,
  Space,
} from 'antd';
import PropTypes from 'prop-types';

import AddFolder from 'components/Icons/AddFolder';
import DeleteIcon from 'components/Icons/DeleteIcon';
import FolderArrow from 'components/Icons/FolderArrow';
import FolderIcon from 'components/Icons/FolderIcon';
import EditIcon from 'components/Icons/EditIcon';
import SavedIcon from 'components/Icons/SavedIcon';
import Prompt from 'components/Prompt';
import utils from 'utils/utils';

import {
  CONTENT_LIBRARY_ADD_CHILD,
  CONTENT_LIBRARY_ADD_ITEM,
  CONTENT_LIBRARY_DELETE_CHILD,
  CONTENT_LIBRARY_RENAME_CHILD,
} from 'graphql/mutations/contentLibraryMutations';
import { GET_TREEVIEW_DATA } from 'graphql/queries/contentLibraryQueries';

import './ContentLibraryTree.scss';

const TreeNode = ({
  data,
  onDelete,
  onUpdateName,
  onDrop,
  activeTreeNode,
  treeUid,
  userId,
  userRole,
}) => {
  const [editable, makeEditable] = useState(false);
  const [value, updateValue] = useState(data.title);
  const [visible, toggleEdit] = useState(false);

  useEffect(() => {
    if (!activeTreeNode) {
      makeEditable(false);
    }
  }, [activeTreeNode]);

  const canUserUpdate = treeUid === userId || data.uid === userId || userRole === 'manager';
  return (
    <span
      className="content-library-tree-title"
      onDrop={onDrop}
      onDragOver={(ev) => ev.preventDefault()}>
      {!data.default && editable && canUserUpdate ? (
        <input
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            if (e.keyCode === 13 && value !== '') {
              e.preventDefault();
              if (onUpdateName) {
                onUpdateName(value);
                makeEditable(false);
              }
            }
          }}
          onBlur={(e) => {
            if (value !== '') {
              if (onUpdateName) {
                onUpdateName(value);
                makeEditable(false);
              }
            }
          }}
          onInput={(e) => updateValue(e.target.value)}
          value={value}
          maxLength={50}
        />
      ) : (
        <Tooltip title={value?.length > 30 ? value : ''}>
          <span onDoubleClick={() => !data.default && canUserUpdate && makeEditable(true)}>
            {utils.ellipsisText(value)}
          </span>
        </Tooltip>
      )}

      {!data.default && canUserUpdate && (
        <>
          {editable ? (
            <SavedIcon
              className="edit-icon"
              size="12"
              onClick={(e) => {
                e.stopPropagation();
                if (value !== '') {
                  onUpdateName(value);
                  makeEditable(false);
                }
              }}
              disabled={value === ''}
            />
          ) : (
            <EditIcon
              className="edit-icon"
              size="12"
              onClick={(e) => {
                e.stopPropagation();
                makeEditable(true);
              }}
            />
          )}

          <Popover
            onClick={(e) => e.stopPropagation()}
            trigger="click"
            visible={visible}
            onVisibleChange={() => toggleEdit(!visible)}
            content={
              <div className="delete-confirm" onClick={(e) => e.stopPropagation()}>
                <h4>Are you sure to delete this folder?</h4>
                <p>Deleting this folder will also delete sub folders & its contents.</p>
                <div className="buttons">
                  <Space size="large">
                    <Button onClick={onDelete} className="delete-btn-small">
                      DELETE
                    </Button>
                    <Button onClick={() => toggleEdit(false)}>CANCEL</Button>
                  </Space>
                </div>
              </div>
            }>
            <DeleteIcon size="18" className={visible && 'delete-icon-visible'} />
          </Popover>
        </>
      )}
    </span>
  );
};

const ContentLibraryTree = forwardRef(
  (
    {
      fromModal,
      onSelect,
      onSelectTreeItem,
      type,
      onUpdate,
      onStarred,
      userId,
      userRole,
      isLocked,
      searchActive,
      toFolderKey,
      isTemplate,
      setFolderName,
    },
    ref
  ) => {
    const [selectedFolderKey, changeSelectedFolderKey] = useState('');
    const [selectedFolderLevel, changeSelectedFolderLevel] = useState(1);
    const [selectedFolderName, changeSelectedFolderName] = useState(1);
    const [newFolderPopup, toggleNewFolderPopup] = useState(false);
    const [treeData, setTreeData] = useState({});
    const [treeSearch, setTreeSearch] = useState('');

    const addKey = useCallback((obj, pkey = '', level = 1) => {
      if (obj && obj.children && obj.children.length) {
        obj.children.forEach((o, i) => {
          const key = `${pkey}children.${i}`;
          o.key = `${key}_${level}_${o.title}`;
          o.path = key;
          addKey(o, `${key}.`, level + 1);
        });
      }

      if (obj && obj.items && obj.items.length) {
        obj.items.forEach((item, i) => {
          item.index = i;
        });
      }
    }, []);

    const onKeySelect = useCallback(
      (k) => {
        changeSelectedFolderKey(k?.[0]?.[0] || '');
        changeSelectedFolderLevel(k?.[0]?.[1] || '');
        changeSelectedFolderName(k?.[0]?.[2] || '');
        setFolderName(k?.[0]?.[2] || '');
        onSelect(k?.map(([v]) => v));
        onSelectTreeItem({
          key: k?.map(([v]) => v)[0],
          treeId: treeData._id,
          name: k?.[0]?.[2] || '',
        });
        onStarred([]);
      },
      [
        changeSelectedFolderKey,
        changeSelectedFolderLevel,
        onSelect,
        onSelectTreeItem,
        onStarred,
        treeData._id,
        setFolderName,
      ]
    );

    const handleTreeData = useCallback(
      (data, skipDefault, keyToBeSelected) => {
        if (!skipDefault) {
          data.key = '';
          addKey(data);
          data.children = data?.children?.reverse();

          if (!fromModal) {
            if (data.children) {
              data.children.splice(0, 0, {
                children: null,
                key: 'starred-folder',
                path: 'starred-folder',
                title: 'All Starred',
                default: true,
              });

              if (type !== 'headers') {
                data.children.splice(0, 0, {
                  children: null,
                  key: 'shared-folder',
                  path: 'shared-folder',
                  title: 'All Shared',
                  default: true,
                });
              }

              data.children.splice(0, 0, {
                children: null,
                key: '',
                path: '',
                title: `All ${type.charAt(0).toUpperCase() + type.slice(1)}`,
                default: true,
              });
            } else {
              data.children = [
                {
                  children: null,
                  key: '',
                  path: '',
                  title: `All ${type.charAt(0).toUpperCase() + type.slice(1)}`,
                  default: true,
                },
                {
                  children: null,
                  key: 'starred-folder',
                  path: 'starred-folder',
                  title: 'All Starred',
                  default: true,
                },
              ];

              if (type !== 'headers') {
                data.children.push({
                  children: null,
                  key: 'shared-folder',
                  path: 'shared-folder',
                  title: 'All Shared',
                  default: true,
                });
              }

              // if (type === 'sections') {
              //   data.children.push({
              //     children: null,
              //     key: 'isHeader',
              //     path: 'isHeader',
              //     title: 'Header',
              //     default: true,
              //   });
              // }
            }
          }
        }

        setTreeData(data);

        if (keyToBeSelected) {
          let key = '';

          for (let contentLibraryTreeItem in data.children) {
            if (data.children[contentLibraryTreeItem].title === keyToBeSelected) {
              key = data.children[contentLibraryTreeItem]?.key;
              break;
            } else if (data.children[contentLibraryTreeItem]?.children?.length) {
              for (let contentLibraryTreeItemChild in data.children[contentLibraryTreeItem]
                ?.children) {
                if (
                  data.children[contentLibraryTreeItem]?.children?.[contentLibraryTreeItemChild]
                    .title === keyToBeSelected
                ) {
                  key =
                    data.children[contentLibraryTreeItem]?.children?.[contentLibraryTreeItemChild]
                      ?.key;
                  break;
                }
              }
            }
          }

          if (key) {
            const k = [key].map((k) => k.split('_'));
            onKeySelect(k);
          }
        } else {
          let key = '';

          for (let contentLibraryTreeItem in data.children) {
            if (
              data.children[contentLibraryTreeItem].path === toFolderKey ||
              data.children[contentLibraryTreeItem].path === toFolderKey?.key
            ) {
              key = data.children[contentLibraryTreeItem]?.key;
              break;
            }
          }

          if (key && !toFolderKey?.treeId) {
            const k = [key].map((k) => k.split('_'));
            onKeySelect(k);
          }
        }
      },
      [setTreeData, addKey, fromModal, type, onKeySelect, toFolderKey]
    );

    const { data, refetch, loading } = useQuery(GET_TREEVIEW_DATA, {
      fetchPolicy: 'network-only',
      variables: {
        type,
      },
      onCompleted: ({ fetchContentLibrary }) =>
        handleTreeData(JSON.parse(JSON.stringify(fetchContentLibrary))),
    });

    const handleRefetch = useCallback(
      async (keyToBeSelected) => {
        const refetchResponse = await refetch({
          variables: {
            type,
          },
        });
        if (refetchResponse?.data) {
          handleTreeData(
            JSON.parse(JSON.stringify(refetchResponse?.data?.fetchContentLibrary)),
            false,
            keyToBeSelected
          );
        }
      },
      [refetch, handleTreeData, type]
    );

    useImperativeHandle(ref, () => ({
      async reloadItems() {
        await handleRefetch();
      },
    }));

    useEffect(() => {
      return () => {
        handleRefetch();
      };
    }, [handleRefetch, type]);

    const [addChild] = useMutation(CONTENT_LIBRARY_ADD_CHILD, {
      onCompleted() {
        onSelectTreeItem({
          key: 'newTemplateFolder',
          value: false,
        });
        notification.success({
          description: 'Created new folder successfully.',
        });
        handleRefetch(treeSearch);
        setTreeSearch('');
      },
    });

    const [addItem] = useMutation(CONTENT_LIBRARY_ADD_ITEM, {
      onCompleted() {
        notification.success({
          description: 'Item moved successfully.',
        });
        if (onUpdate) {
          onUpdate();
        }
        handleRefetch();
      },
    });

    const [deleteChild] = useMutation(CONTENT_LIBRARY_DELETE_CHILD, {
      onCompleted() {
        notification.success({
          description: 'Folder deleted successfully.',
        });
        handleRefetch();
        onKeySelect(['']);
      },
    });

    const [renameChild] = useMutation(CONTENT_LIBRARY_RENAME_CHILD, {
      onCompleted() {
        notification.success({
          description: 'Folder renamed successfully.',
        });
        handleRefetch();
      },
    });

    // let treeData = JSON.parse(JSON.stringify((data && data.fetchContentLibrary) || {}));

    const handleTreeSearch = (e) => {
      const tempSearchValue = e.target.value;
      let tempTreeData = JSON.parse(JSON.stringify(treeData)) || {};
      let skipDefault = false;
      let tempTreeDataChildren =
        JSON.parse(JSON.stringify(data?.fetchContentLibrary))?.children || [];

      setTreeSearch(tempSearchValue);

      if (tempTreeDataChildren?.length) {
        tempTreeData.children = tempTreeDataChildren.filter(
          (child) => child.title?.toLowerCase().search(tempSearchValue) !== -1
        );
      }

      if (!tempTreeDataChildren?.length || !tempTreeData.children?.length) {
        skipDefault = true;
        if (tempSearchValue?.trim()) {
          tempTreeData.children = [
            {
              children: null,
              default: true,
              key: `newTemplateFolder_${tempSearchValue?.trim()}`,
              path: '',
              title: `Create folder "${tempSearchValue?.trim()}"`,
            },
          ];
        } else {
          tempTreeData.children = [];
        }
      }

      handleTreeData(JSON.parse(JSON.stringify(tempTreeData)), skipDefault);
    };

    const addNewChildFromSearch = (k) => {
      onSelectTreeItem({
        key: 'newTemplateFolder',
        value: true,
      });
      addChild({
        variables: {
          child: { title: k[0][1], uid: userId },
          key: `${selectedFolderKey}${selectedFolderKey ? '.' : ''}children`,
          id: treeData._id,
        },
      });
    };

    const handleSearchKeyDown = (e) => {
      if (e.key === 'Enter') {
        if (treeData?.children?.length === 1) {
          const k = treeData.children?.[0]?.key?.split('_');
          if (k?.[0] === 'newTemplateFolder') {
            addNewChildFromSearch([k]);
          }
        }
      }
    };

    if (!treeData) return null;
    return (
      <div className="split-d1">
        {searchActive && (
          <Input
            className="tree-node-search-input"
            value={treeSearch}
            style={{ marginBottom: 8 }}
            placeholder="Search or create folder"
            onChange={handleTreeSearch}
            onKeyDown={handleSearchKeyDown}
          />
        )}
        <div className="sidemenu-list">
          {loading ? (
            Array.from({ length: 3 }, (_, arrayIndex) => (
              <Skeleton.Input key={arrayIndex} active={true} />
            ))
          ) : (
            <Tree
              allowDrop={({ dropNode, dropPosition }) => console.log(dropNode, dropPosition)}
              showLine
              showIcon
              switcherIcon={<FolderArrow />}
              defaultSelectedKeys={['']}
              selectable
              selectedKeys={[`${selectedFolderKey}_${selectedFolderLevel}_${selectedFolderName}`]}
              onSelect={(keys) => {
                const k = keys.map((k) => k.split('_'));
                if (k?.[0]?.[0] === 'newTemplateFolder') {
                  addNewChildFromSearch(k);
                } else {
                  onKeySelect(k);
                }
              }}
              treeData={(treeData.children || []).filter((item) => item.title !== null)}
              icon={(data) => (data.title ? <FolderIcon /> : <></>)}
              titleRender={(data) => (
                <TreeNode
                  onDrop={(ev) => {
                    ev.preventDefault();
                    if (selectedFolderKey === data.path || data.key === 'newTemplateFolder') return;
                    const body = JSON.parse(ev.dataTransfer.getData('data'));
                    addItem({
                      variables: {
                        fromKey: selectedFolderKey || '',
                        id: treeData._id,
                        key: data.path,
                        item_id: body._id,
                        type: 'template',
                      },
                    });
                    // todo: call the api
                  }}
                  activeTreeNode={
                    selectedFolderKey === data.path || data.key === 'new_template_folder'
                  }
                  checkedKeys={[selectedFolderKey]}
                  data={data}
                  userId={userId}
                  userRole={userRole}
                  treeUid={treeData.uid}
                  onDelete={() => {
                    // eslint-disable-next-line
                    deleteChild({
                      variables: {
                        id: treeData._id,
                        key: data.path,
                      },
                    });
                  }}
                  onUpdateName={(name) => {
                    renameChild({
                      variables: {
                        id: treeData._id,
                        key: data.path,
                        name,
                      },
                    });
                  }}
                />
              )}
            />
          )}
        </div>
        <Button
          disabled={selectedFolderLevel > 3 || isLocked}
          className="content-library-new-folder"
          icon={<AddFolder />}
          size="large"
          block
          onClick={() => toggleNewFolderPopup(true)}>
          New Folder
        </Button>
        {newFolderPopup && (
          <Prompt
            visible={newFolderPopup}
            cancelText="CANCEL"
            confirmText="ADD"
            handleCancel={() => toggleNewFolderPopup(false)}
            maxLength={40}
            handleConfirm={(title) => {
              toggleNewFolderPopup(false);
              const trimmedTitle = title?.trim();
              if (trimmedTitle) {
                let ifExists = false;
                for (let child of treeData.children) {
                  if (child.title === trimmedTitle) {
                    ifExists = true;
                  }
                }
                if (ifExists) {
                  return message.error('Folder already exists with same name');
                }
                addChild({
                  variables: {
                    child: { title: trimmedTitle, uid: userId },
                    key: `${selectedFolderKey}${selectedFolderKey ? '.' : ''}children`,
                    id: treeData._id,
                  },
                });
              } else {
                message.error('Folder name required.');
              }
            }}
            title="Enter Folder Name"
          />
        )}
      </div>
    );
  }
);

ContentLibraryTree.defaultProps = {
  onStarred: () => {},
  onSelectTreeItem: () => {},
  type: '',
  userId: '',
  userRole: '',
  isLocked: false,
  searchActive: false,
  setFolderName: () => {},
};

ContentLibraryTree.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSelectTreeItem: PropTypes.func,
  onStarred: PropTypes.func,
  type: PropTypes.string,
  userId: PropTypes.string,
  userRole: PropTypes.string,
  isLocked: PropTypes.bool,
  searchActive: PropTypes.bool,
  setFolderName: PropTypes.func,
};

export default ContentLibraryTree;
